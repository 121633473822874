import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Link as MuiLink,
  TextField,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { HelperText } from "../../Empadronamiento/HelperText.jsx";
import { useState } from "react";
import axios from "../../../utils/axios.js";
import hosteo from "../../../utils/hosteo.js";
import { LoadingButton } from "@mui/lab";
import {
  MobileDateRangePicker,
  DesktopDateRangePicker,
} from "@material-ui/lab";
import es from "date-fns/locale/es";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { BrowserView, MobileView } from "react-device-detect";
import { enqueueSnackbar } from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, esES } from "@mui/x-data-grid";
import * as XLSX from "xlsx/xlsx.js";
import DownloadIcon from "@mui/icons-material/Download";

export default function PracticasSolicitadas() {
  const [value, setValue] = useState([null, null]);
  const [filtrado, setFiltrado] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [datos, setDatos] = useState([]);

  const Cabecera = () => {
    return (
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Practicas solicitadas
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <MuiLink component={NavLink} to="/dashboard/home">
              Home
            </MuiLink>
            <MuiLink component={NavLink} to="/dashboard/auditoria">
              Auditoria
            </MuiLink>
            <Typography>Practicas solicitadas</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
    );
  };

  const buscador = (e) => {
    const inputValue = e.target.value.toLowerCase();

    const resultados = datos?.filter((datos) => {
      // Verificar si alguno de los campos contiene el valor buscado
      return Object.values(datos).some(
        (campo) =>
          typeof campo === "string" && campo.toLowerCase().includes(inputValue)
      );
    });
    setFiltrado(resultados);
  };

  const obtenerDatos = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${hosteo}/api/auditoria/getPrestadores`,
        {
          desde: value[0].toISOString().split("T")[0],
          hasta: value[1].toISOString().split("T")[0],
        }
      );
      setDatos(
        response.data.map((item, index) => ({ ...item, id: index + 1 }))
      );
      setFiltrado(
        response.data.map((item, index) => ({ ...item, id: index + 1 }))
      );
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      field: "fecha",
      headerName: "Fecha",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "codigo",
      headerName: "Codigo",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "prestacion",
      headerName: "Prestacion",
      minWidth: 150,
      flex: 1,
    },
    { field: "nbenef", headerName: "N° Beneficiario", minWidth: 150, flex: 1 },
    { field: "paren", headerName: "Parentesco", minWidth: 150, flex: 1 },
    {
      field: "ndoc",
      headerName: "DNI Beneficiario",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "nombre",
      headerName: "Beneficiario",
      minWidth: 150,
      flex: 1,
    },

    {
      field: "medaut",
      headerName: "Medico Auditor",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "medsol",
      headerName: "Medico solicitante",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => (params.value === null ? "-" : params.value),
    },
    {
      field: "institu",
      headerName: "Institucion",
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => (params.value === null ? "-" : params.value),
    },
  ];

  const generateAndDownloadExcel = () => {
    let nombre = "Pedido de anticonceptivos";

    const workbook = XLSX.utils.book_new();

    // Crea una nueva hoja de cálculo con datos
    const dia = new Date();
    const options = {
      timeZone: "America/Buenos_Aires",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    // Encabezado original
    const encabezado = [
      [`Reporte de ${nombre}`],
      [
        `Generado a las ${dia
          .toLocaleTimeString("es-AR", options)
          .slice(0, 5)}hs del ${dia.toLocaleDateString()}`,
      ],
      [""],
    ];

    // Títulos de las columnas
    const columnTitles = columns.map((column) => column.headerName);

    // Unir encabezado original con nuevos títulos de columna
    const datosConTitulos = [...encabezado, columnTitles];

    // Agregar datos a la hoja de cálculo
    filtrado.forEach((item) => {
      const rowData = columns.map((column) => item[column.field]);
      datosConTitulos.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(datosConTitulos);
    XLSX.utils.book_append_sheet(workbook, worksheet, nombre);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${nombre}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Helmet title="Prestadores por fecha" />
      <Cabecera />
      <Divider my={2} />
      <Box sx={{ mt: 2 }}>
        <HelperText
          message={
            "Mostrará los prestadores que se encuentren dentro del rango de fechas seleccionado"
          }
        />
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Card sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
              "@media (max-width: 700px)": {
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              },
            }}
          >
            <MobileView>
              <MobileDateRangePicker
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "& .css-wne9d8": {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                }}
                toolbarTitle="Seleccione entre fechas"
                inputFormat="dd/MM/yyyy"
                startText="Desde"
                endText="Hasta"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <Grid
                      container
                      spacing={1}
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Grid item sm={12} xs={12} width="100%">
                        <TextField
                          name="desde"
                          autoComplete="off"
                          {...startProps}
                        />
                      </Grid>

                      <Grid item xs={12} width="100%">
                        <TextField
                          name="hasta"
                          autoComplete="off"
                          {...endProps}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              />
            </MobileView>
            <BrowserView
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DesktopDateRangePicker
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                inputFormat="dd/MM/yyyy"
                startText="Desde"
                endText="Hasta"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      name="desde"
                      autoComplete="off"
                      {...startProps}
                    />
                    <Box sx={{ mx: 2 }}> - </Box>
                    <TextField name="hasta" autoComplete="off" {...endProps} />
                  </>
                )}
              />
            </BrowserView>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              onClick={obtenerDatos}
            >
              Buscar
            </LoadingButton>
          </Box>
        </Card>
      </LocalizationProvider>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            my: 10,
          }}
        >
          <CircularProgress size={75} />
        </Box>
      ) : (
        <>
          {datos.length > 0 && (
            <Card sx={{ p: 3, mt: 5 }}>
              <Box
                container
                sx={{
                  marginBottom: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box item xl={11} xs={10} sm={10} md={11}>
                  <TextField
                    fullWidth
                    label="Buscar..."
                    onChange={(e) => buscador(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <div>
                <Box sx={{ display: "flex", justifyContent: "center", my: 3 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={generateAndDownloadExcel}
                    endIcon={<DownloadIcon />}
                  >
                    EXCEL
                  </Button>
                </Box>
                <DataGrid
                  sx={{ padding: 1 }}
                  disableRowSelectionOnClick
                  autoHeight
                  localeText={
                    esES.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={filtrado || []}
                  columns={columns}
                  initialState={{
                    pagination: { paginationModel: { page: 0, pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                />
              </div>
            </Card>
          )}
        </>
      )}
    </>
  );
}
