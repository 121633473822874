import { useEffect, useState, useRef } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import esES from "date-fns/locale/es";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Autocomplete from "@mui/material/Autocomplete";
import CustomLabel from "../../../theme/CustomLabel";
import axios from "../../../utils/axios";
import hosteo from "../../../utils/hosteo";
import {
  convertirFecha,
  convertirFechaSend,
} from "../../../utils/formatNumber";
import { enqueueSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  sexo,
  relacionLaboralABM,
  incapacitado,
  tipoDomicilio,
} from "../../../components/Empadronamiento/Beneficiarios/listasABMtitular";
import { DataGrid, esES as es } from "@mui/x-data-grid";
import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  TextField,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ModalLocalidades } from "../Beneficiarios/ModalLocalidades";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

export default function ABMTitular({ setCuilTitular }) {
  const [situacionRevista, setSituacionRevista] = useState([]);
  const [tipoBenef, setTipoBenef] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [nacionalidades, setNacionalidades] = useState([]);
  const [estadoCivil, setEstadoCivil] = useState([]);
  const [codObraSocial, setCodObraSocial] = useState([]);
  const [bajaMotivos, setBajaMotivos] = useState([]);
  const [openMulti, setOpenMulti] = useState(false);
  const [rows, setRows] = useState([]);
  const [disabledCuil, setDisabledCuil] = useState(false);
  const [cuits, setCuits] = useState([]);
  const [openSelectCuit, setOpenSelectCuit] = useState(false);
  const [estabaBaja, setEstabaBaja] = useState(1);
  const [fecBajaAnt, setFecBajaAnt] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMsg, setDialogMsg] = useState("");
  const [queNovedad, setQueNovedad] = useState("MODI");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingDL, setIsEditingDL] = useState(false);
  const [previousData, setPreviousData] = useState(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const [disableNbenef, setDisableNbenef] = useState(false);

  const columns = [
    {
      field: "codigo",
      headerName: "Codigo",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "zona",
      headerName: "Zona",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "razon",
      headerName: "Razon",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "action",
      headerName: "",
      align: "right",
      maxWidth: 100,
      editable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          aria-label="more"
          aria-controls="menu"
          aria-haspopup="true"
          onClick={() => handleSelectCuit(params.row)}
        >
          Seleccionar
        </Button>
      ),
    },
  ];

  const handleSelectCuit = (row) => {
    setFieldValue("zona", row.zona);
    setFieldValue("empresa", row.codigo);
    setFieldValue("establecimiento", row.razon);
    setOpenSelectCuit(false);
  };

  const handleCloseDialog = () => {
    setQueNovedad("MODI");
    setOpenDialog(false);
  };

  const handleContinue = () => {
    setOpenDialog(false);
    handleSendData();
  };

  function obtenerObjPorId(id, array) {
    const documentoEncontrado = array.find((doc) => doc.id === id);
    if (documentoEncontrado) {
      return documentoEncontrado;
    } else {
      return null;
    }
  }

  // ------------------------------------------------------------------------------------------
  const fetchListas = async () => {
    try {
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/getListasABM`
      );
      setSituacionRevista(response.data.situacionRevista);
      setTipoBenef(response.data.tipoBenef);
      setTipoDocumento(response.data.tipoDocumento);
      setProvincias(response.data.provincias);
      setNacionalidades(response.data.nacionalidades);
      setEstadoCivil(response.data.estadoCivil);
      setCodObraSocial(response.data.codObraSocial);
      setBajaMotivos(response.data.baja);
    } catch (error) {
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    }
  };
  useEffect(() => {
    fetchListas();
  }, []);

  const verifyTelefono = (numero) => {
    if (numero === "" || !numero) {
      return "";
    } else if (typeof numero === "string") {
      return numero.replace(/-/g, "");
    } else {
      return numero;
    }
  };

  const handleValidCuil = async (value, type) => {
    try {
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/validCuil`,
        { [type]: value }
      );
      setDisabledCuil(true);
      setQueNovedad("MODI");
      if (response.data.beneficiario.baja !== null) {
        setEstabaBaja(2);
        setFecBajaAnt(response.data.beneficiario.baja);
      } else {
        setEstabaBaja(1);
      }
      setIsEmpty(false);
      setValues({
        cuil: response.data.beneficiario.cuil,
        numeroBenef: response.data.beneficiario.nbenef,
        nombre: response.data.beneficiario.nombre,
        tipoDocumentoId: tipoDocumento.find(
          (element) => element.id === response.data.beneficiario.tdoc
        ),
        numDocumento: response.data.beneficiario.numdoc,
        numAfiliado: response.data.beneficiario.afiliado,
        fechaNacimiento: convertirFecha(response.data.beneficiario.fnacim),
        sexoId: sexo.find(
          (element) => element.id === response.data.beneficiario.sexo
        ),
        nacionalidadesId: nacionalidades.find(
          (element) => element.id === response.data.beneficiario.nacio
        ),
        estadoCivilId: estadoCivil.find(
          (element) => element.id === response.data.beneficiario.estciv
        ),
        telefonoParticular: formatPhone(response.data.beneficiario.telefono),
        incapacitadoId: obtenerObjPorId(
          response.data.beneficiario.incapas,
          incapacitado
        ),
        tipoBenefId: obtenerObjPorId(
          response.data.beneficiario.tbenef,
          tipoBenef
        ),
        monotSocial: response.data.beneficiario.monsoc === 1,
        calle: response.data.beneficiario.calle,
        nroCalle: response.data.beneficiario.puerta,
        piso: response.data.beneficiario.piso,
        dto: response.data.beneficiario.depto,
        cpa: response.data.beneficiario.codpos,
        provincia: obtenerObjPorId(
          response.data.beneficiario.codpro,
          provincias
        ),
        localidad: response.data.beneficiario.localidad,
        tipoDom: obtenerObjPorId(
          response.data.beneficiario.tdom,
          tipoDomicilio
        ),
        cuit: response.data.beneficiario.cuit,
        zona: response.data.beneficiario.zona,
        empresa: response.data.beneficiario.empresa,
        establecimiento: response.data.beneficiario.establecimiento,
        ingreso: convertirFecha(response.data.beneficiario.altaoso),
        relLaboral: obtenerObjPorId(
          response.data.beneficiario.ramalab,
          relacionLaboralABM
        ),
        codOS: obtenerObjPorId(
          response.data.beneficiario.osocial,
          codObraSocial
        ),
        situRevista: obtenerObjPorId(
          response.data.beneficiario.sitrev,
          situacionRevista
        ),
        motivoBaja:
          response.data.beneficiario.m_baja === 0 &&
          response.data.beneficiario.baja !== null
            ? obtenerObjPorId(2, bajaMotivos)
            : obtenerObjPorId(response.data.beneficiario.m_baja, bajaMotivos),
        fecBaja: convertirFecha(response.data.beneficiario.baja),
        fondoDesempleo: convertirFecha(response.data.beneficiario.fdes),
      });
      setDisableNbenef(true);
      if (
        (response.data.beneficiario.localidad === "" ||
          response.data.beneficiario.localidad === null) &&
        response.data.beneficiario.codpos !== ""
      ) {
        handleCpos(response.data.beneficiario.codpos);
      }
    } catch (error) {
      console.log("error ValidCuil", error.message);
      setDisabledCuil(false);
    }
  };

  const handleValidCuit = async (value) => {
    try {
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/validCuit`,
        { cuit: value }
      );
      if (response.data.length === 1) {
        setValues({
          ...values,
          cuit: value,
          zona: response.data[0].zona,
          empresa: response.data[0].codigo,
          establecimiento: response.data[0].razon,
          monotSocial: 0,
        });
      } else {
        setCuits(response.data);
        setOpenSelectCuit(true);
      }
    } catch (error) {
      console.log("error ValidCuit", error.message);
    }
  };

  const handleCpos = async (value) => {
    try {
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/checkCpos`,
        {
          cpos: value,
        }
      );
      if (response?.data?.localidades?.length > 0) {
        setRows(response?.data?.localidades);
        setOpenMulti(true);
      } else {
        setFieldValue(
          "provincia",
          obtenerObjPorId(response.data.prov, provincias)
        );
        setFieldValue("localidad", response.data.nombre);
      }
    } catch (error) {
      console.log("Error:", error || "No hay internet");
    }
  };

  const handleSet = (dato) => {
    setFieldValue("provincia", obtenerObjPorId(dato.prov, provincias));
    setFieldValue("localidad", dato.nombre);
    setOpenMulti(false);
  };

  // ------------------ Initial values y schema ------------------------------------

  const validationSchemaCrear = Yup.object().shape({
    cuil: Yup.mixed()
      .required("Es requerido")
      .test("validar-cuil", "El CUIL no es válido", (value) => {
        let ddg = String(value).trim().slice(0, 2);
        if (["20", "23", "24", "27"].indexOf(ddg) === -1) {
          return false;
        }
        if (/^\d{11}$/.test(value)) {
          return true;
        } else {
          return false;
        }
      }),
    numeroBenef: Yup.number("Debe ser un numero").required("Es requerido"),
    nombre: Yup.string()
      .required("Es requerido")
      .max(45, "El maximo de caracteres es 45"),
    tipoDocumentoId: Yup.object().required("Es requerido"),
    numDocumento: Yup.number("Debe ser un nummero")
      .required("Es requerida")
      .test(
        "cuil-match",
        "El número de documento debe coincidir con el CUIL",
        function (value) {
          if (
            Number(this.parent.cuil?.toString().substring(2, 10)) === value ||
            !this.parent.cuil?.toString().substring(2, 10)
          ) {
            return true;
          } else {
            return false;
          }
        }
      ),
    numAfiliado: Yup.number("Debe ser un nummero").required("Es requerida"),
    fechaNacimiento: Yup.string().required("Es requerida"),
    sexoId: Yup.object().required("Es requerido"),
    nacionalidadesId: Yup.object().required("Es requerido"),
    estadoCivilId: Yup.object().required("Es requerido"),
    telefonoParticular: Yup.string().max(
      20,
      "El maximo de caracteres es de 20"
    ),
    incapacitadoId: Yup.object().required("Es requerido"),
    tipoBenefId: Yup.object().required("Es requerido"),
    calle: Yup.string().required("Es requerido"),
    nroCalle: Yup.number().max(99999, "No puede superar los 5 digitos"),
    cpa: Yup.number()
      .required("Es requerido")
      .max(9999, "No puede superar los 4 digitos"),
    provincia: Yup.object().required("Es requerido"),
    localidad: Yup.string().max(35, "El maximo de caracteres es de 35"),
    tipoDom: Yup.object().required("Es requerido"),
    cuit: Yup.mixed()
      .required("Es requerido")
      .test("validar-cuit", "El CUIT no es válido", (value) => {
        if (/^\d{11}$/.test(value)) {
          return true;
        } else {
          return false;
        }
      }),
    zona: Yup.number()
      .required("Es requerido")
      .max(9999, "No puede superar los 4 digitos"),
    empresa: Yup.number()
      .required("Es requerido")
      .max(9999, "No puede superar los 4 digitos"),
    // establecimiento: Yup.string().required("Es requerido"),
    ingreso: Yup.mixed().required("Es requerido"),
    relLaboral: Yup.object().required("Es requerido"),
    // codOS: Yup.string(),
    // situRevista: Yup.string(),
    ddjjs: Yup.string(),
    pago: Yup.string(),
    reducAportes: Yup.string(),
    transf: Yup.string(),
  });

  const initialValues = {
    cuil: "",
    numeroBenef: "",
    nombre: "",
    tipoDocumentoId: null,
    numDocumento: "",
    numAfiliado: "",
    fechaNacimiento: null,
    sexoId: null,
    nacionalidadesId: null,
    estadoCivilId: null,
    telefonoParticular: "",
    incapacitadoId: null,
    tipoBenefId: null,
    monotSocial: false,
    calle: "",
    nroCalle: "",
    piso: "",
    dto: "",
    cpa: "",
    provincia: null,
    localidad: "",
    tipoDom: null,
    cuit: "",
    zona: "",
    empresa: "",
    establecimiento: "",
    ingreso: null,
    relLaboral: null,
    codOS: null,
    situRevista: null,
    motivoBaja: null,
    fecBaja: null,
    fondoDesempleo: null,
    ddjjs: "",
    pago: "",
    reducAportes: "",
    transf: "",
  };

  const handleResetForm = () => {
    setEstabaBaja(1);
    setDisabledCuil(false);
    resetForm();
    setQueNovedad("MODI");
  };

  const handleCleanDomicilio = () => {
    setFieldValue("calle", "");
    setFieldValue("nroCalle", "");
    setFieldValue("piso", "");
    setFieldValue("dto", "");
    setFieldValue("cpa", "");
    setFieldValue("provincia", null);
    setFieldValue("localidad", "");
    setFieldValue("tipoDom", null);
  };

  const handleCleanDatosLaborales = () => {
    setFieldValue("cuit", "");
    setFieldValue("zona", "");
    setFieldValue("empresa", "");
    setFieldValue("establecimiento", "");
    setFieldValue("monotSocial", false);
    setFieldValue("ingreso", null);
    setFieldValue("relLaboral", null);
    setFieldValue("codOS", null);
    setFieldValue("situRevista", null);
  };

  const handleCleanBaja = () => {
    setFieldValue("motivoBaja", null);
    setFieldValue("fecBaja", null);
    setFieldValue("fondoDesempleo", null);
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaCrear,
    initialValues: initialValues,
    onSubmit: (values) => {
      setIsLoading(true);
      if (validarBaja(values)) {
        handleSendData();
      }
      setIsLoading(false);
    },
  });

  function formatPhone(numero) {
    // Eliminar todos los caracteres que no sean dígitos
    if (!numero) {
      return "";
    }
    const numeroSoloDigitos = verifyTelefono(numero.toString());
    console.log(numeroSoloDigitos);

    let part1 = numeroSoloDigitos.slice(0, 2) || "";
    let part2 = numeroSoloDigitos.slice(2, 6) || "";
    let part3 = numeroSoloDigitos.slice(6, 15) || "";
    if (numeroSoloDigitos.length === 11) {
      part1 = numeroSoloDigitos.slice(0, 3) || "";
      part2 = numeroSoloDigitos.slice(3, 7) || "";
      part3 = numeroSoloDigitos.slice(7, 16) || "";
    }
    if (numeroSoloDigitos.length >= 12) {
      part1 = numeroSoloDigitos.slice(0, 4) || "";
      part2 = numeroSoloDigitos.slice(4, 8) || "";
      part3 = numeroSoloDigitos.slice(8, 17) || "";
    }
    if (numeroSoloDigitos.length <= 2) {
      return part1;
    }
    if (numeroSoloDigitos.length <= 6) {
      return `${part1}-${part2}`;
    } else {
      // Aplicar el formato deseado
      return `${part1}-${part2}-${part3}`;
    }
  }

  const handleSendData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/sendBenef`,
        {
          cuil: values.cuil,
          numeroBenef: values.numeroBenef,
          nombre: values.nombre,
          tipoDocumentoId: values.tipoDocumentoId?.id,
          numDocumento: values.numDocumento,
          numAfiliado: values.numAfiliado,
          fechaNacimiento: convertirFechaSend(values.fechaNacimiento),
          monotSocial: values.monotSocial ? 1 : 0,
          sexoId: values.sexoId.id,
          nacionalidadesId: values.nacionalidadesId?.id,
          estadoCivilId: values.estadoCivilId?.id,
          telefonoParticular: verifyTelefono(values.telefonoParticular),
          incapacitadoId: values.incapacitadoId?.id,
          tipoBenefId: values.tipoBenefId?.id,
          calle: values.calle,
          nroCalle: values.nroCalle,
          piso: values.piso,
          dto: values.dto,
          cpa: values.cpa,
          provincia: values.provincia?.id,
          localidad: values.localidad,
          tipoDom: values.tipoDom?.id,
          cuit: values.cuit,
          zona: values.zona,
          empresa: values.empresa,
          establecimiento: values.establecimiento,
          ingreso: convertirFechaSend(values.ingreso),
          especialidad: values.relLaboral?.db,
          relLaboral: values.relLaboral?.id,
          codOS: values.codOS?.id,
          situRevista: values.situRevista?.id,
          motivoBaja: values.motivoBaja?.id,
          fecBaja: convertirFechaSend(values.fecBaja),
          fondoDesempleo: convertirFechaSend(values.fondoDesempleo),
          estabaBaja: estabaBaja,
          queNovedad: queNovedad,
        }
      );
      setIsLoading(false);
      handleCloseDialog();
      handleResetForm();
      setDisableNbenef(false);
      enqueueSnackbar(response.data.message, { variant: "success" });
      setIsEmpty(true);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    }
  };

  const handleSendDatosLab = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${hosteo}/api/empadronamiento/beneficiarios/sendDatosLab`,
        {
          nbenef: values.numeroBenef,
          cuil: values.cuil,
          cuit: values.cuit,
          zona: values.zona,
          empresa: values.empresa,
          establecimiento: values.establecimiento,
          fechaIngreso: convertirFechaSend(values.ingreso),
          especialidad: values.relLaboral?.db,
          relLab: values.relLaboral?.id,
          codOS: values.codOS?.id,
          situRevista: values.situRevista?.id,
          monotSocial: values.monotSocial ? 1 : 0,
        }
      );
      setIsLoading(false);
      enqueueSnackbar(response.data.message, { variant: "success" });
      setIsEditingDL(false);
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error.message || "No tienes conexión a internet", {
        variant: "error",
      });
    }
  };

  const validarBaja = (values) => {
    // estabaBaja === 2 // Tenia fecha de baja originalmente
    // estabaBaja === 1 // No tenia fecha de baja originalmente

    if (estabaBaja === 2 && values.fecBaja === null) {
      // Si tenia fecha de baja originalmente, y ahora no tiene, es una alta
      setDialogTitle("Reincorporación de Beneficiario");
      setDialogMsg("El beneficiario esta de Baja, ¿le da nuevamente el alta?");
      setQueNovedad("ALTA");
      setOpenDialog(true);

      return false;
    } else if (estabaBaja === 1 && values.fecBaja != null) {
      // Si no tenia fecha de baja originalmente, y ahora si tiene, es una baja
      setDialogTitle("Baja  de Beneficiario");
      setDialogMsg("Esta seguro de la baja");
      setQueNovedad("BAJA");
      setOpenDialog(true);

      return false;
    } else if (
      estabaBaja === 2 &&
      convertirFechaSend(values.fecBaja) < fecBajaAnt
    ) {
      setDialogTitle("Baja  de Beneficiario");
      setDialogMsg("Esta seguro de la baja");
      setQueNovedad("BAJA");
      setOpenDialog(true);
      return false;
    } else {
      return true;
    }
  };

  const {
    handleSubmit,
    touched,
    errors,
    getFieldProps,
    setValues,
    handleBlur,
    setFieldValue,
    resetForm,
    values,
  } = formik;
  // -------------------------------------------------------------------------------

  const handleChange = (value) => {
    setFieldValue("relLaboral", value);
    if (value.id !== 3) {
      setFieldValue("monotSocial", false);
    }
  };
  // -------------------Funcion para pasar de campo con el Enter-------------------
  const handleKeyDown = (event, nextInputId) => {
    if (event.key === "Enter") {
      if (nextInputId === 6) {
        datePickerRef.current.focus();
      } else if (nextInputId === 25) {
        datePickerRef2.current.focus();
      } else if (nextInputId === 30) {
        datePickerRef3.current.focus();
      } else if (nextInputId === 31) {
        datePickerRef4.current.focus();
      } else if (nextInputId === 36) {
        console.log("listo");
      } else {
        document.getElementById(nextInputId + 1).focus();
      }
    }
    console.log(nextInputId + 1);
  };
  const datePickerRef = useRef(null);
  const datePickerRef2 = useRef(null);
  const datePickerRef3 = useRef(null);
  const datePickerRef4 = useRef(null);

  const clearCuil = () => {
    resetForm();
    setDisabledCuil(false);
    handleResetForm();
  };

  const handleEditDL = () => {
    setIsEditingDL(true);
    setPreviousData({
      cuit: values.cuit,
      zona: values.zona,
      empresa: values.empresa,
      establecimiento: values.establecimiento,
      ingreso: values.ingreso,
      especialidad: values.relLaboral?.db,
      relLaboral: obtenerObjPorId(values.relLaboral?.id, relacionLaboralABM),
      codOS: obtenerObjPorId(values.codOS?.id, codObraSocial),
      situRevista: obtenerObjPorId(values.situRevista?.id, situacionRevista),
      monotSocial: values.monotSocial ? 1 : 0,
    });
  };

  const handleCancelEditDL = () => {
    setIsEditingDL(false);
    setValues({
      ...values,
      ...previousData,
    });
    setPreviousData(null);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Typography sx={{ marginY: 2 }} textAlign={"center"} variant="h4">
          Datos personales
        </Typography>
        <Card sx={{ p: 4 }}>
          <Grid container spacing={3}>
            <Grid item md={2.4}>
              <TextField
                autoFocus
                type="number"
                label={[<CustomLabel>CUIL</CustomLabel>]}
                {...getFieldProps("cuil")}
                error={Boolean(touched.cuil && errors.cuil)}
                helperText={touched.cuil && errors.cuil}
                disabled={isLoading || disabledCuil}
                id={1}
                onChange={(e) => {
                  setFieldValue("cuil", e.target.value);
                  setCuilTitular(e.target.value);
                  if (e.target.value.length === 11 && !disabledCuil) {
                    handleValidCuil(e.target.value, "cuil");
                  }
                }}
                onKeyDown={(e) => handleKeyDown(e, 1)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {disabledCuil && (
                        <IconButton onClick={clearCuil}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={2.4}>
              <TextField
                label={[<CustomLabel>N° de documento</CustomLabel>]}
                type="number"
                {...getFieldProps("numDocumento")}
                error={Boolean(touched.numDocumento && errors.numDocumento)}
                helperText={touched.numDocumento && errors.numDocumento}
                onChange={(e) => {
                  setFieldValue("numDocumento", e.target.value);
                  if (e.target.value.length === 8 && !disabledCuil) {
                    handleValidCuil(e.target.value, "dni");
                  }
                }}
                disabled={isLoading}
                id={5}
                onKeyDown={(e) => handleKeyDown(e, 5)}
              />
            </Grid>
            <Grid item md={2.2}>
              <TextField
                label={[<CustomLabel>N° Beneficiario</CustomLabel>]}
                type="number"
                {...getFieldProps("numeroBenef")}
                error={Boolean(touched.numeroBenef && errors.numeroBenef)}
                helperText={touched.numeroBenef && errors.numeroBenef}
                disabled={disableNbenef || isLoading}
                id={2}
                onKeyDown={(e) => handleKeyDown(e, 2)}
              />
            </Grid>
            <Grid item md={2.6}>
              <TextField
                label={[<CustomLabel>Apellido y nombre</CustomLabel>]}
                {...getFieldProps("nombre")}
                error={Boolean(touched.nombre && errors.nombre)}
                helperText={touched.nombre && errors.nombre}
                disabled={isLoading}
                id={3}
                onKeyDown={(e) => handleKeyDown(e, 3)}
              />
            </Grid>
            <Grid item md={2.4}>
              <Autocomplete
                disablePortal
                autoHighlight
                autoSelect
                onBlur={handleBlur}
                options={tipoDocumento}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={values.tipoDocumentoId}
                disabled={isLoading}
                onChange={(event, value) => {
                  setFieldValue("tipoDocumentoId", value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ p: 0 }}
                    {...params}
                    onBlur={handleBlur}
                    label={[<CustomLabel>Tipo documento</CustomLabel>]}
                    error={Boolean(
                      touched.tipoDocumentoId && errors.tipoDocumentoId
                    )}
                    helperText={
                      touched.tipoDocumentoId && errors.tipoDocumentoId
                    }
                  />
                )}
                id={4}
                onKeyDown={(e) => handleKeyDown(e, 4)}
              />
            </Grid>

            <Grid item md={2}>
              <TextField
                label={[<CustomLabel>N° afiliado</CustomLabel>]}
                type="number"
                {...getFieldProps("numAfiliado")}
                error={Boolean(touched.numAfiliado && errors.numAfiliado)}
                helperText={touched.numAfiliado && errors.numAfiliado}
                disabled={isLoading}
                id={6}
                onKeyDown={(e) => handleKeyDown(e, 6)}
              />
            </Grid>
            <Grid item md={2}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esES}
              >
                <DesktopDatePicker
                  label={<CustomLabel>Fecha de nacimiento</CustomLabel>}
                  value={values.fechaNacimiento}
                  slotProps={{
                    textField: {
                      error: Boolean(
                        touched.fechaNacimiento && errors.fechaNacimiento
                      ),
                      helperText:
                        touched.fechaNacimiento && errors.fechaNacimiento,
                    },
                  }}
                  onChange={(newValue) => {
                    setFieldValue("fechaNacimiento", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} onBlur={handleBlur} />
                  )}
                  disabled={isLoading}
                  id={7}
                  onAccept={() => {
                    setTimeout(() => {
                      document.getElementById(8).focus();
                    }, "50");
                  }}
                  inputRef={datePickerRef}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={2}>
              <Autocomplete
                disablePortal
                autoHighlight
                autoSelect
                onBlur={handleBlur}
                options={sexo}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={values.sexoId}
                onChange={(event, value) => {
                  setFieldValue("sexoId", value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ p: 0 }}
                    {...params}
                    onBlur={handleBlur}
                    label={[<CustomLabel>Sexo</CustomLabel>]}
                    error={Boolean(touched.sexoId && errors.sexoId)}
                    helperText={touched.sexoId && errors.sexoId}
                  />
                )}
                disabled={isLoading}
                id={8}
                onKeyDown={(e) => handleKeyDown(e, 8)}
              />
            </Grid>
            <Grid item md={2}>
              <Autocomplete
                disablePortal
                autoHighlight
                autoSelect
                onBlur={handleBlur}
                options={nacionalidades}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={values.nacionalidadesId}
                disabled={isLoading}
                onChange={(event, value) => {
                  setFieldValue("nacionalidadesId", value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ p: 0 }}
                    {...params}
                    onBlur={handleBlur}
                    label={[<CustomLabel>Nacionalidad</CustomLabel>]}
                    error={Boolean(
                      touched.nacionalidadesId && errors.nacionalidadesId
                    )}
                    helperText={
                      touched.nacionalidadesId && errors.nacionalidadesId
                    }
                  />
                )}
                id={9}
                onKeyDown={(e) => handleKeyDown(e, 9)}
              />
            </Grid>
            <Grid item md={2}>
              <Autocomplete
                disablePortal
                autoHighlight
                autoSelect
                options={estadoCivil}
                onBlur={handleBlur}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                getOptionLabel={(option) => `${option.label}(${option.id})`}
                getOptionValue={(option) => option.id}
                value={values.estadoCivilId}
                disabled={isLoading}
                onChange={(event, value) => {
                  setFieldValue("estadoCivilId", value ? value : null); // Guarda el ID en un nuevo campo
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ p: 0 }}
                    {...params}
                    onBlur={handleBlur}
                    label={[<CustomLabel>Estado Civil</CustomLabel>]}
                    error={Boolean(
                      touched.estadoCivilId && errors.estadoCivilId
                    )}
                    helperText={touched.estadoCivilId && errors.estadoCivilId}
                  />
                )}
                id={10}
                onKeyDown={(e) => handleKeyDown(e, 10)}
              />
            </Grid>
            <Grid item md={2}>
              <TextField
                label={[<CustomLabel>Tel. particular</CustomLabel>]}
                // {...getFieldProps("telefonoParticular")}
                error={Boolean(
                  touched.telefonoParticular && errors.telefonoParticular
                )}
                {...getFieldProps("telefonoParticular")}
                value={values.telefonoParticular}
                onChange={(event, value) => {
                  setFieldValue(
                    "telefonoParticular",
                    formatPhone(event.target.value)
                  );
                }}
                helperText={
                  touched.telefonoParticular && errors.telefonoParticular
                }
                disabled={isLoading}
                id={11}
                onKeyDown={(e) => handleKeyDown(e, 11)}
              />
            </Grid>
            <Grid item md={3}>
              <Autocomplete
                disablePortal
                autoHighlight
                autoSelect
                onBlur={handleBlur}
                options={incapacitado}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={values.incapacitadoId}
                disabled={isLoading}
                onChange={(event, value) => {
                  setFieldValue("incapacitadoId", value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ p: 0 }}
                    {...params}
                    onBlur={handleBlur}
                    label={[<CustomLabel>Incapacitado</CustomLabel>]}
                    error={Boolean(
                      touched.incapacitadoId && errors.incapacitadoId
                    )}
                    helperText={touched.incapacitadoId && errors.incapacitadoId}
                  />
                )}
                id={12}
                onKeyDown={(e) => handleKeyDown(e, 12)}
              />
            </Grid>
            <Grid item md={3}>
              <Autocomplete
                disablePortal
                autoHighlight
                autoSelect
                disabled={isLoading}
                options={tipoBenef}
                onBlur={handleBlur}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={values.tipoBenefId}
                onChange={(event, value) => {
                  setFieldValue("tipoBenefId", value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ p: 0 }}
                    {...params}
                    onBlur={handleBlur}
                    label={[<CustomLabel>Tipo de Beneficiario</CustomLabel>]}
                    error={Boolean(touched.tipoBenefId && errors.tipoBenefId)}
                    helperText={touched.tipoBenefId && errors.tipoBenefId}
                  />
                )}
                id={13}
                onKeyDown={(e) => handleKeyDown(e, 13)}
              />
            </Grid>
          </Grid>
        </Card>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography sx={{ marginY: 3 }} textAlign={"center"} variant="h4">
            Domicilio
          </Typography>
          <Button onClick={handleCleanDomicilio}>
            <CloseIcon />
          </Button>
        </Box>
        <Card sx={{ padding: 3 }}>
          <Grid container gap={3}>
            <Grid item md={3}>
              <TextField
                fullWidth
                label={[<CustomLabel>Calle</CustomLabel>]}
                {...getFieldProps("calle")}
                error={Boolean(touched.calle && errors.calle)}
                helperText={touched.calle && errors.calle}
                disabled={isLoading}
                id={14}
                onKeyDown={(e) => handleKeyDown(e, 14)}
              />
            </Grid>
            <Grid item md={1.5}>
              <TextField
                label={[<CustomLabel>N°</CustomLabel>]}
                type="number"
                {...getFieldProps("nroCalle")}
                error={Boolean(touched.nroCalle && errors.nroCalle)}
                helperText={touched.nroCalle && errors.nroCalle}
                disabled={isLoading}
                id={15}
                onKeyDown={(e) => handleKeyDown(e, 15)}
              />
            </Grid>
            <Grid item md={1.5}>
              <TextField
                label={[<CustomLabel>Piso</CustomLabel>]}
                {...getFieldProps("piso")}
                error={Boolean(touched.piso && errors.piso)}
                helperText={touched.piso && errors.piso}
                disabled={isLoading}
                id={16}
                onKeyDown={(e) => handleKeyDown(e, 16)}
              />
            </Grid>
            <Grid item md={1.5}>
              <TextField
                label={[<CustomLabel>Depto</CustomLabel>]}
                {...getFieldProps("dto")}
                error={Boolean(touched.dto && errors.dto)}
                helperText={touched.dto && errors.dto}
                disabled={isLoading}
                id={17}
                onKeyDown={(e) => handleKeyDown(e, 17)}
              />
            </Grid>
            <Grid item md={1.5}>
              <TextField
                label={[<CustomLabel>C. Postal</CustomLabel>]}
                type="number"
                {...getFieldProps("cpa")}
                onChange={(e) => {
                  setFieldValue("cpa", e.target.value);
                  if (e.target.value.length === 4) {
                    handleCpos(e.target.value);
                  }
                }}
                error={Boolean(touched.cpa && errors.cpa)}
                helperText={touched.cpa && errors.cpa}
                disabled={isLoading}
                id={18}
                onKeyDown={(e) => handleKeyDown(e, 18)}
              />
            </Grid>
            <Grid item md={2}>
              <Autocomplete
                disabled={isLoading}
                disablePortal
                autoHighlight
                autoSelect
                options={provincias}
                value={values.provincia}
                onBlur={handleBlur}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                onChange={(event, value) => {
                  setFieldValue("provincia", value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={handleBlur}
                    label={[<CustomLabel>Provincia</CustomLabel>]}
                    error={Boolean(touched.provincia && errors.provincia)}
                    helperText={touched.provincia && errors.provincia}
                  />
                )}
                id={19}
                onKeyDown={(e) => handleKeyDown(e, 19)}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                fullWidth
                disabled={isLoading}
                label={[<CustomLabel>Localidad</CustomLabel>]}
                {...getFieldProps("localidad")}
                error={Boolean(touched.localidad && errors.localidad)}
                helperText={touched.localidad && errors.localidad}
                id={20}
                onKeyDown={(e) => handleKeyDown(e, 20)}
              />
            </Grid>
            <Grid item md={3}>
              <Autocomplete
                fullWidth
                disabled={isLoading}
                disablePortal
                options={tipoDomicilio}
                onBlur={handleBlur}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={values.tipoDom}
                onChange={(event, value) => {
                  setFieldValue("tipoDom", value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={handleBlur}
                    label={[<CustomLabel>Tipo de domicilio</CustomLabel>]}
                    error={Boolean(touched.tipoDom && errors.tipoDom)}
                    helperText={touched.tipoDom && errors.tipoDom}
                  />
                )}
                id={21}
                onKeyDown={(e) => handleKeyDown(e, 21)}
              />
            </Grid>
          </Grid>
          <ModalLocalidades
            open={openMulti}
            handleSet={handleSet}
            datos={rows}
            handleClose={() => setOpenMulti(false)}
            provs={provincias}
          />
        </Card>

        {/* --------------------------------------------------------------------------------------------------- */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {!isEmpty && (
            <Button onClick={isEditingDL ? handleCancelEditDL : handleEditDL}>
              <EditIcon />
            </Button>
          )}
          <Typography sx={{ marginY: 3 }} textAlign={"center"} variant="h4">
            Datos Laborales
          </Typography>
          {isEditingDL && (
            <Button onClick={handleSendDatosLab}>
              <SaveIcon />
            </Button>
          )}
          {isEditingDL && (
            <Button onClick={handleCleanDatosLaborales}>
              <CloseIcon />
            </Button>
          )}
        </Box>

        <Card sx={{ padding: 3 }}>
          <Grid container gap={3}>
            <Grid item md={3} sm={2}>
              <TextField
                fullWidth
                disabled={isLoading || (!isEditingDL && !isEmpty)}
                label={[<CustomLabel>CUIT</CustomLabel>]}
                type="number"
                {...getFieldProps("cuit")}
                onChange={(e) => {
                  setFieldValue("cuit", e.target.value);
                  if (e.target.value.length === 11) {
                    handleValidCuit(e.target.value);
                  }
                }}
                error={Boolean(touched.cuit && errors.cuit)}
                helperText={touched.cuit && errors.cuit}
                id={22}
                onKeyDown={(e) => handleKeyDown(e, 22)}
              />
            </Grid>
            <Grid item md={1.5} sm={1}>
              <TextField
                disabled={isLoading || (!isEditingDL && !isEmpty)}
                label={[<CustomLabel>Zona</CustomLabel>]}
                type="number"
                {...getFieldProps("zona")}
                error={Boolean(touched.zona && errors.zona)}
                helperText={touched.zona && errors.zona}
                id={23}
                onKeyDown={(e) => handleKeyDown(e, 23)}
              />
            </Grid>
            <Grid item md={2} sm={1.3}>
              <TextField
                disabled={isLoading || (!isEditingDL && !isEmpty)}
                label={[<CustomLabel>Empresa</CustomLabel>]}
                type="number"
                {...getFieldProps("empresa")}
                error={Boolean(touched.empresa && errors.empresa)}
                helperText={touched.empresa && errors.empresa}
                id={24}
                onKeyDown={(e) => handleKeyDown(e, 24)}
              />
            </Grid>
            <Grid item md={2.5} sm={2}>
              <TextField
                disabled={isLoading || (!isEditingDL && !isEmpty)}
                label={[<CustomLabel>Establecimiento</CustomLabel>]}
                {...getFieldProps("establecimiento")}
                error={Boolean(
                  touched.establecimiento && errors.establecimiento
                )}
                helperText={touched.establecimiento && errors.establecimiento}
                id={25}
                onKeyDown={(e) => handleKeyDown(e, 25)}
              />
            </Grid>
            <Grid md={2} sm={2}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={esES}
              >
                <DesktopDatePicker
                  label={[<CustomLabel>Ingreso</CustomLabel>]}
                  value={values.ingreso}
                  slotProps={{
                    textField: {
                      error: Boolean(touched.ingreso && errors.ingreso),
                      helperText: touched.ingreso && errors.ingreso,
                    },
                  }}
                  onChange={(newValue) => {
                    setFieldValue("ingreso", newValue);
                  }}
                  disabled={isLoading || (!isEditingDL && !isEmpty)}
                  renderInput={(params) => <TextField {...params} />}
                  inputRef={datePickerRef2}
                  id={26}
                  onAccept={() => {
                    setTimeout(() => {
                      document.getElementById(27).focus();
                    }, "50");
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item md={3} sm={3}>
              <Autocomplete
                disabled={isLoading || (!isEditingDL && !isEmpty)}
                disablePortal
                autoHighlight
                autoSelect
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={relacionLaboralABM}
                value={values.relLaboral}
                onBlur={handleBlur}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                onChange={(event, value) => {
                  setFieldValue("relLaboral", value ? value : null);
                  // cambiar esta funcion de abajo

                  handleChange(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={[<CustomLabel>Relacion Laboral</CustomLabel>]}
                    onBlur={handleBlur}
                    error={Boolean(touched.relLaboral && errors.relLaboral)}
                    helperText={touched.relLaboral && errors.relLaboral}
                  />
                )}
                id={27}
                onKeyDown={(e) => handleKeyDown(e, 27)}
              />
            </Grid>
            <Grid item md={2} sm={3}>
              <Autocomplete
                disabled={isLoading || (!isEditingDL && !isEmpty)}
                disablePortal
                autoHighlight
                autoSelect
                onBlur={handleBlur}
                options={codObraSocial}
                value={values.codOS}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                onChange={(event, value) => {
                  setFieldValue("codOS", value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={[<CustomLabel>Cod.OS</CustomLabel>]}
                    onBlur={handleBlur}
                    error={Boolean(touched.codOS && errors.codOS)}
                    helperText={touched.codOS && errors.codOS}
                  />
                )}
                id={28}
                onKeyDown={(e) => handleKeyDown(e, 28)}
              />
            </Grid>
            <Grid md={3} sm={3}>
              <Autocomplete
                disabled={isLoading || (!isEditingDL && !isEmpty)}
                disablePortal
                autoHighlight
                autoSelect
                onBlur={handleBlur}
                options={situacionRevista}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                value={values.situRevista}
                onChange={(event, value) => {
                  setFieldValue("situRevista", value ? value : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={[<CustomLabel>Situacion que revista</CustomLabel>]}
                    onBlur={handleBlur}
                    error={Boolean(touched.situRevista && errors.situRevista)}
                    helperText={touched.situRevista && errors.situRevista}
                  />
                )}
                id={29}
                onKeyDown={(e) => handleKeyDown(e, 29)}
              />
            </Grid>

            <Grid
              item
              md={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      values.relLaboral?.id !== 3 ||
                      isLoading ||
                      (!isEditingDL && !isEmpty)
                    }
                    checked={values.monotSocial}
                    onClick={(event) => {
                      setFieldValue("monotSocial", event.target.checked);
                    }}
                  />
                }
                label={[<CustomLabel>Mono Social</CustomLabel>]}
                labelPlacement="end"
              />
            </Grid>
          </Grid>
          {/* <Button variant="contained" sx={{ mt: 2 }}>
                Imprimir solicitud
              </Button> */}
        </Card>

        <Grid container spacing={2}>
          {/* --------------------------------------------------------------------------------------------------- */}
          <Grid item md={6} sm={6}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography sx={{ marginY: 3 }} textAlign={"center"} variant="h4">
                Baja del afiliado
              </Typography>
              <Button onClick={handleCleanBaja}>
                <CloseIcon />
              </Button>
            </Box>
            <Card sx={{ padding: 3, margin: 1 }}>
              <Grid md={12}>
                <Autocomplete
                  disablePortal
                  autoHighlight
                  autoSelect
                  disabled={isLoading}
                  onBlur={handleBlur}
                  options={bajaMotivos}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={values.motivoBaja}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.id}
                  onChange={(event, value) => {
                    setFieldValue("motivoBaja", value ? value : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={[<CustomLabel>Motivo</CustomLabel>]}
                      onBlur={handleBlur}
                      error={Boolean(touched.motivoBaja && errors.motivoBaja)}
                      helperText={touched.motivoBaja && errors.motivoBaja}
                    />
                  )}
                  id={30}
                  onKeyDown={(e) => handleKeyDown(e, 30)}
                />
              </Grid>
              <Grid
                container
                marginY={3}
                display={"flex"}
                justifyContent={"center"}
                gap={2}
              >
                <Grid md={5} sm={5}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esES}
                  >
                    <DesktopDatePicker
                      fullWidth
                      label={[<CustomLabel>Fecha de Baja</CustomLabel>]}
                      value={values.fecBaja}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.fecBaja && errors.fecBaja),
                          helperText: touched.fecBaja && errors.fecBaja,
                        },
                      }}
                      disabled={isLoading}
                      onChange={(newValue) => {
                        setFieldValue("fecBaja", newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      inputRef={datePickerRef3}
                      id={31}
                      onAccept={() => {
                        setTimeout(() => {
                          datePickerRef4.current.focus();
                        }, "50");
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid md={5} sm={5}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={esES}
                  >
                    <DesktopDatePicker
                      fullWidth
                      label={[<CustomLabel>Fondo de desempleo</CustomLabel>]}
                      {...getFieldProps("fondoDesempleo")}
                      value={values.fondoDesempleo}
                      disabled={isLoading}
                      slotProps={{
                        textField: {
                          error: Boolean(
                            touched.fondoDesempleo && errors.fondoDesempleo
                          ),
                          helperText:
                            touched.fondoDesempleo && errors.fondoDesempleo,
                        },
                      }}
                      onChange={(newValue) => {
                        setFieldValue("fondoDesempleo", newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      inputRef={datePickerRef4}
                      id={32}
                      onAccept={() => {
                        setTimeout(() => {
                          document.getElementById(33).focus();
                        }, "50");
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {/* --------------------------------------------------------------------------------------------------- */}
          <Grid item md={6} sm={5}>
            <Typography sx={{ marginY: 3 }} textAlign={"center"} variant="h4">
              Información Suministrada por AFIP (Ultimos períodos informados)
            </Typography>
            <Card sx={{ padding: 3, margin: 1 }}>
              <Grid justifyContent={"center"} container spacing={2}>
                <Grid item md={6} sm={5}>
                  <TextField
                    label={[<CustomLabel>Declaraciones juradas</CustomLabel>]}
                    disabled={isLoading}
                    {...getFieldProps("ddjjs")}
                    id={33}
                    onKeyDown={(e) => handleKeyDown(e, 33)}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} sm={5}>
                  <TextField
                    disabled={isLoading}
                    label={[<CustomLabel>Pago</CustomLabel>]}
                    {...getFieldProps("pago")}
                    id={34}
                    onKeyDown={(e) => handleKeyDown(e, 34)}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} sm={5}>
                  <TextField
                    disabled={isLoading}
                    label={[<CustomLabel>Reducción de aportes</CustomLabel>]}
                    {...getFieldProps("reducAportes")}
                    id={35}
                    onKeyDown={(e) => handleKeyDown(e, 35)}
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} sm={5}>
                  <TextField
                    disabled={isLoading}
                    label={[<CustomLabel>Transferencias</CustomLabel>]}
                    {...getFieldProps("transf")}
                    id={36}
                    onKeyDown={(e) => handleKeyDown(e, 36)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            disabled={isLoading || isEditingDL}
            onClick={formik.handleSubmit}
            sx={{ mt: 4 }}
          >
            Guardar
          </LoadingButton>
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancelar</Button>
            <Button onClick={handleContinue} autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openSelectCuit}
          onClose={() => setOpenSelectCuit(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {" "}
            Selecciona una empresa
          </DialogTitle>
          <DialogContent>
            <DataGrid
              autoHeight
              localeText={es.components.MuiDataGrid.defaultProps.localeText}
              rows={cuits}
              getRowId={(row) => row.orden}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { page: 0, pageSize: 5 } },
              }}
              pageSizeOptions={[5]}
              density="compact"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenSelectCuit(false)}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}
