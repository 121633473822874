import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
} from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";

export const ModalLocalidades = ({
  open,
  handleClose,
  datos,
  handleSet,
  provs,
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const handleSelect = async (prov) => {
    handleSet(prov);
  };

  if (!Array.isArray(datos)) {
    return null;
  }

  function setProvName(id) {
    const provEncontrado = provs?.find((prov) => prov.id === id);
    return provEncontrado?.label;
  }

  const rowsWithId = datos?.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  const columns = [
    {
      field: "nombre",
      headerName: "Nombre",
      flex: 1,
      editable: false,
    },
    {
      field: "prov",
      headerName: "Provincia",
      flex: 1,
      editable: false,
      valueFormatter: (params) => setProvName(params.value),
    },
    {
      field: "cpos",
      headerName: "Codigo postal",
      flex: 1,
      editable: false,
    },
    {
      field: "action",
      headerName: "",
      align: "right",
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          aria-label="more"
          aria-controls="menu"
          aria-haspopup="true"
          onClick={() => handleSelect(params.row)}
        >
          Seleccionar
        </Button>
      ),
    },
  ];

  const handlePaginationChange = (params) => {
    if (params.pageSize !== pageSize) {
      setPageSize(params.pageSize);
      setCurrentPage(0);
    } else {
      setCurrentPage(params.page);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
      <DialogTitle>Elija una localidad</DialogTitle>
      <DialogContent>
        {datos?.length > 0 ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <DataGrid
              autoHeight
              sx={{ height: "70vh" }}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              disableRowSelectionOnClick
              onPaginationModelChange={(row) => handlePaginationChange(row)}
              onPageChange={(newPage) => setCurrentPage(newPage)}
              paginationModel={{
                page: currentPage,
                pageSize: pageSize,
              }}
              rows={rowsWithId}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { page: 0, pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h4">
              No se encontraron localidades asociadas al codigo postal
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
