import * as React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ABMTitular2 from "../../../components/Empadronamiento/ArchivosAFIP/ABMTitular2";
import ABMFamiliar from "../../../components/Empadronamiento/ArchivosAFIP/ABMFamiliar";
import { useState } from "react";

import { Grid, Breadcrumbs as MuiBreadcrumbs, Link } from "@mui/material";
import { spacing } from "@mui/system";
import CustomTabPanel, { a11yProps } from "../../../utils/CustomTabPanel";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Cabecera = () => {
  return (
    <Grid justifyContent="space-between" container spacing={6}>
      <Grid item>
        <Typography variant="h3" gutterBottom>
          Alta, Baja y Modificaciones de Beneficiarios
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to="/dashboard/home">
            Home
          </Link>
          <Link component={NavLink} to="/dashboard/empadronamiento">
            Empadronamiento
          </Link>
          <Typography>ABM Beneficiarios</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
};

export default function ABMBeneficiarios() {
  const [value, setValue] = useState(0);
  const [cuilTitular, setCuilTitular] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* <BuscoBenef
        handleBusqueda={handleBusqueda}
        handleChosen={handleChosen}
        datosBef={datosBef}
        handleNext={handleNext}
        openBuscar={openBuscar}
        // isLoading={isLoading}
        closeBuscar={handleCloseBuscar}
      /> */}
      <Helmet title="Empadronamiento" />
      <Cabecera />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Titular" {...a11yProps(0)} />
          <Tab label="Familiar" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {/* <ABMTitular /> */}
        <ABMTitular2 setCuilTitular={setCuilTitular} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ABMFamiliar cuilTitular={cuilTitular} />
      </CustomTabPanel>
    </Box>
  );
}
