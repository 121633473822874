import React, { useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
// import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
//import { useTranslation } from "react-i18next";

import {
  Box,
  Grid,
  // InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";

import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import NavBarDarkMode from "./NavBarDarkMode";
import DialogBuscador from "./DialogBuscador";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AppBar position="sticky" elevation={2}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { lg: "block", xl: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Box>
              <SearchIcon onClick={handleClickOpen} />
            </Box>
            <DialogBuscador
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
            />
            <Grid item xs />
            <Grid item>
              <NavBarDarkMode />
              <NavbarMessagesDropdown />
              <NavbarNotificationsDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withTheme(Navbar);
